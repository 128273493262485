import React from 'react'
import './Header.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp'

function Header() {
  return (
    <div className='header'>
      <h2>Welcome To</h2>
      <img src='./jsr_logo.jpg' style={{width:"180px",height:"150px"}}/>
      <FloatingWhatsApp
      accountName='JSR Book'
      phoneNumber='+919471741373'
      avatar='./jsr_logo.jpg'
      statusMessage='Since 2010'
      chatMessage='Hello there! 🤝 Grab your id now'/>
    </div>
  )
}

export default Header

