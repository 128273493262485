import React from 'react'
import './Info.css';

function Info() {
  return (
    <div className='info'>
      <p style={{fontSize:".9rem",color:"#eeba00"}}>#BeHereOrBeNoWhere</p>
      <p>Experience the best of all gaming on JSRPunt app.</p>
      <p className='para'>Happy Punting!</p>
      <p>Grab your id now   <a href="https://api.whatsapp.com/send?phone=+919471741373"> @ +919471741373</a> </p>
      <div className='detail'>
        <p className='head'>About JSR Book</p>
        <p className='body'>
          JSR Book is a cricket betting and trading website that has been in operation since 2010.
          This service is regarded the largest book exchange platform for cricket betting.
          It distinguishes itself from other exchange platforms due to its simple deposit and withdrawal options. 
          JSR owns or operates this website. We communicate exclusively via Whatsapp.
        </p>
      </div>
      <div className='detail'>
        <p className='head'>Why Choose JSR Book</p>
        <p className='body'>At JSR Book, we stand out as your premier choice for online gaming.
         Offering a diverse range of games sites, cutting-edge technology, and a commitment to responsible gaming, 
         we create an unmatched and thrilling experience for our users. Choose JSR for a secure and enjoyable gaming environment
          where excitement knows no bounds. 
        Join us and discover the difference that sets JSR apart in the world of online entertainment.</p>
      </div>
      <p style={{marginTop:".8rem"}}>Grab your id now   <a href="https://api.whatsapp.com/send?phone=+919471741373"> @ +919471741373</a> </p>
      <p id='disclaimer'>Disclaimer:- This Website is only for 18+ users. If you are from Telangana, Orissa, Assam, Sikkim, and Nagaland Please leave the website immediately. Be aware of fraudsters,</p>
    </div>
  )
}

export default Info
