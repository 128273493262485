import React from 'react'
import './Infos.css' ;
import { FloatingWhatsApp } from 'react-floating-whatsapp'

function info1() {
  return (
    <div className='info1-container'>
      <h2>YAARO KA YAAR...JSR BOOK Most Trusted Book Since 2010</h2>
      <p id='contact'>Grab your id now   <a id='contact-a' href="https://api.whatsapp.com/send?phone=+917250537373"> @ +917250537373</a> </p>
      <div style={{ backgroundImage: "url(./bet_casino.jpg)",backgroundPosition: "center",
        backgroundSize: "cover", padding:"1rem .1rem", width:"100%",}}>
      <div className='ids'>
        <h3>Whitelabel</h3>
        <p><a href="https://20wickets.com">https://20wickets.com</a></p>
        <p><a href="https://55sports.in">https://55sports.in</a></p>
        <p><a href="https://punt444.com">https://punt444.com</a></p>
      </div>
      <div className='ids'>
        <h3>Premium</h3>
        <p><a href="https://www.skyinplay.com/">https://www.skyinplay.com/</a></p>
        <p><a href="https://lordsexch.com">https://lordsexch.com</a></p>
        <p><a href="https://allpanelexch.com/">https://allpanelexch.com/</a></p>
      </div>
      </div>
      <p id='bonus'>5% Bonus Flat on Deposit</p>
      <p id='contact'>Grab your id now   <a id='contact-a' href="https://api.whatsapp.com/send?phone=+917250537373"> @ +917250537373</a> </p>
      <p id='panel'>Get your Panel, Super master, Mater from us.</p>
      <p id='type'>All type of sites are available at minimum rate</p>
      {/* <p id='app'>For Self Deposit & Withdrawl Install the app</p> */}
    </div>
  )
}

export default info1
